<template>
  <div>
    <div class="d-flex align-center justify-end px-4">
      <ProjectsStatisticsIssuesFilter
        :filter-params="employeeIssueStatisticsFilterParams"
        @change="$router.push({ name: 'employeeIssueStatistics', query: $event })"
        @reset="resetProjectsStatisticsIssuesFilters"
      />
    </div>

    <div :class="{ 'content-loading': loading['get:api/project-issues-statistic'] }">
      <div class="pa-3">
        <HorizontalBarChart :chart-data="countsChartData" />
      </div>

      <div class="pa-3">
        <HorizontalBarChart :chart-data="timesChartData" />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsStatisticsIssuesFilter from '@/components/filters/ProjectsIssueStatisticsFilter';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getSanitizedFilterParams } from '@/util/filter-params';
import round from '@/util/numbers';
import HorizontalBarChart from '@/charts/HorizontalBarChart';
import { getDefaultEmployeeIssueStatisticsFilterParams } from '@/store/modules/employee-issue-statistics-module';

export default {
  name: 'ProjectsStatisticsIssues',

  components: { HorizontalBarChart, ProjectsStatisticsIssuesFilter },

  computed: {
    ...mapState('employeeIssueStatistics', [
      'employeeIssueStatistics',
      'employeeIssueStatisticsFilterParams',
    ]),
    ...mapGetters(['loading']),

    countsChartData() {
      const labels = [];
      const solvedIssueCounts = [];
      const unsolvedIssueCounts = [];

      for (let i = 0; i < this.employeeIssueStatistics.length; i++) {
        const employee = this.employeeIssueStatistics[i];

        if (employee.user_id) {
          labels.push(employee.user_name || '-');
          solvedIssueCounts.push(
            +employee.solved_normal + +employee.solved_important + +employee.solved_critical
          );
          unsolvedIssueCounts.push(
            +employee.unsolved_normal + +employee.unsolved_important + +employee.unsolved_critical
          );
        }
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('projects.labels.solved'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: solvedIssueCounts,
          },
          {
            label: this.$t('projects.labels.unsolved'),
            backgroundColor: this.$vuetify.theme.currentTheme.error,
            data: unsolvedIssueCounts,
          },
        ],
      };
    },

    timesChartData() {
      const labels = [];
      const seeingTimes = [];
      const solvingTimes = [];

      for (let i = 0; i < this.employeeIssueStatistics.length; i++) {
        const employee = this.employeeIssueStatistics[i];

        if (employee.user_id) {
          labels.push(employee.user_name || '-');
          seeingTimes.push(round(employee.average_seen_in_minutes / 60, 1));
          solvingTimes.push(round(employee.average_solved_in_minutes / 60, 1));
        }
      }

      return {
        labels,
        datasets: [
          {
            label: this.$t('projects.labels.average_issue_seeing_time'),
            backgroundColor: this.$vuetify.theme.currentTheme.success,
            data: seeingTimes,
          },
          {
            label: this.$t('projects.labels.average_issue_solving_time'),
            backgroundColor: this.$vuetify.theme.currentTheme.error,
            data: solvingTimes,
          },
        ],
      };
    },
  },

  created() {
    this.fetchEmployeeIssueStatistics(getSanitizedFilterParams(this.$route.query));
  },

  beforeRouteUpdate(to, from, next) {
    const params = getSanitizedFilterParams(to.query);
    if (
      JSON.stringify(this.employeeIssueStatisticsFilterParams) !== JSON.stringify(params) &&
      to.name === 'employeeIssueStatistics'
    ) {
      this.fetchEmployeeIssueStatistics(to.query);
    }
    next();
  },

  methods: {
    ...mapActions('employeeIssueStatistics', ['fetchEmployeeIssueStatistics']),

    resetProjectsStatisticsIssuesFilters() {
      const defaultFilters = getDefaultEmployeeIssueStatisticsFilterParams();
      if (
        JSON.stringify(defaultFilters) === JSON.stringify(this.employeeIssueStatisticsFilterParams)
      ) {
        return;
      }
      this.$router.push({ name: 'employeeIssueStatistics', query: defaultFilters });
    },
  },
};
</script>

<style scoped></style>
